
import { Component, Prop, Vue } from "vue-property-decorator";
import { CallNumber } from "@ionic-native/call-number";

type Tile = {
  id: number;
};

@Component
export default class NotrufComponent extends Vue {
  @Prop(Object) readonly tile!: Tile;

  localTile: null | Tile = null;

  get userData() {
    return this.$store.getters["profile/data"];
  }

  created() {
    this.$store.dispatch("app/setTile", this.tile.id).then(({ data }) => {
      this.localTile = this.hasRole("guest") ? { ...this.tile } : { ...data };
    });
  }

  callMedicall() {
    CallNumber.callNumber("0848111811", true)
      .then(res => console.log("Launched dialer!", res))
      .catch(err => console.log("Error launching dialer", err));
  }

  hasRole(role: string): boolean {
    return this.userData?.roles.some(r => r.slug == role);
  }
}
